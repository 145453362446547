<template>
  <v-container class="privacy-page container-wrapper">
    <v-row>
      <v-col class="privacy-page__header px-xl-0">
        <h1>Privacy Policy</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="privacy-page__content px-xl-0">
        <h4>Terms & Conditions</h4>
        <p>We are committed to protecting your privacy. This Privacy Policy describes how and why we process your personal data when you access our website at
          <a href="www.ari.company" target="_blank">www.ari.company</a> (hereinafter “the website”), in the course of our marketing and recruitment activities, for communicating our services and business development.</p>
        <p>This policy also sets forth your privacy rights and explains how to get in touch with us if needed.</p>
        <h4>Who are we?</h4>
        <p>Individual entrepreneur Oleh Dankevych (hereinafter “we,” “us,” “our,” or “ARI”) has created this website to communicate with casual visitors, potential clients, and companies interested in our services as well as to provide information about ARI and the IT outsourcing industry. We welcome visitors to casually view information on our website and to use the site to directly communicate with ARI</p>
        <p>We control all personal data processed in accordance with this policy.</p>
        <p>By visiting this website, you accept our Privacy Policy and agree that we may collect personal data during your website visit. If you disagree with this Privacy Policy, please feel free to leave the website.</p>
        <p>This website is not intended to offer any services directly to children. We do not knowingly collect information from children. However, if you are under the age of 16 and want to get in touch with us, please, send us a written consent from your parents or guardians to process your personal information.</p>

        <h4>What data do we collect?</h4>
        <p>We collect, store and use data that is essential for giving visitors the maximum benefit from our website.</p>
        <p>We collect the following data during your website session:</p>
        <ul>
          <li>Information you provide directly via contact forms or email messages, including your email address, phone number, name, surname, company name, job title, postal address and other information you are willing to provide us</li>
          <li>Data about your behaviour on web pages during your visit and information about how you navigate the website</li>
          <li>Statistics, such as particular pages you visit, the number of unique website entries, the browser from which you visit the website, your screen resolution, and the platform you use</li>
        </ul>
        <p>Also, we collect your personal data in the course of our marketing activity and communication of our services in the most convenient way. In addition, we may use your personal data for secondary purposes closely related to the mentioned above purposes, in circumstances where you would reasonably expect such use.</p>
        <p>In the course of our marketing and recruitment activities, for communicating our services and business development we may collect the following data:</p>
        <ul>
          <li>Information collected from you or third parties or from public sources, including your email address, phone number, name, surname, company name, job title, postal address etc.</li>
          <li>Information you provide to us when using the services or information which is generated in the course of the use of those services (including the timing, frequency and pattern of service use).</li>
          <li>Information contained in or relating to any communication that you send to us or send through this website (including correspondence with us, communication content and metadata associated with the communication).</li>
          <li>Information you provide to participate in ARI events, live or web conferences</li>
          <li>Any other personal data that you decided to share with us.</li>
        </ul>
        <p>The above-mentioned data is collected on the basis of your consent or other legal basis presented below and in compliance with applicable laws, along with required filings with data protection authorities, where required. You can send your consent by email to the address ceo@ari.company.</p>
        <p>We collect most non‐public personal information directly from you or your agents whether in person, by telephone or electronically.</p>
        <p>In some circumstances, we may be provided with your personal information by third parties (e.g. someone recommended you for potential employment). In such cases, we take reasonable steps to ensure that you are made aware of the data provided to us by the third party.</p>
        <p>ARI does not intentionally collect any sensitive information about you.</p>

        <h4>How do we use this data?</h4>
        <p>Personal data submitted to us through this website and personal data we collected from you, third parties or public sources will be used (processed) for the purposes specified in this Policy.</p>
        <p>We will use your personal data on the following legal basis:</p>
        <ul>
          <li>Legitimate interest (Article 6 para. 1 (f) GDPR)</li>
          <li>Consent (Article 6 para. 1 (a) GDPR)</li>
          <li>Another legal basis available under Article 6 GDPR</li>
        </ul>
        <p>As a rule, your personal data will be used to:</p>
        <ul>
          <li>Respond to your inquiries and send you requests or promotional materials.</li>
          <li>Improve your website experience during your next session.</li>
          <li>Offer you specific content based on your previous use or based on usability trends shown by the behavior of other users on this site or by other studies.</li>
          <li>Tailor specific offers or show targeted content via suggestions, newsletters, or customized pages.</li>
          <li>Communicate with you via different platforms, such as social media, email, telephone, and other networks. You can opt out of receiving this targeted content. Still, for other campaigns, we may use your data to deliver improved messaging for other users.</li>
          <li>Analyze our marketing campaigns, previous deals, and current customers.</li>
          <li>Comply with internal and external audit requirements as well as for information security purposes.</li>
          <li>Comply with legal requirements, such as preventing crimes, aiding in investigations, or preventing loss or fraud, in which case we may have to share data within the scope of legal proceedings.</li>
        </ul>
        <p>Personal data processed by ARI is not subject to profiling and automated decision-making.</p>

        <h4>Whom will we share your data with?</h4>
        <p>In the course of our business activities, we may disclose your personal data to service providers and partners who assist us in operating this website or in our marketing and recruiting activities, to affiliated companies and, in some cases, to legal authorities.</p>
        <p>We may disclose your personal data to the following third parties:</p>
        <ul>
          <li>Service providers, contractors, third-party vendors, agents, or partners who act on our request or on our behalf</li>
          <li>Our affiliates or subsidiaries that may help us process it</li>
          <li>Legal authorities in order to comply with the law, a judicial proceeding, a court order, or another legal process, such as in response to a court order or a subpoena</li>
          <li>Relevant third parties in case of acquisition of our company or merger with another company</li>
        </ul>
        <p>Except in those specific, limited situations, without your consent, we will not make any disclosures of non‐public personal information to other companies who may want to sell their products or services to you.</p>

        <h4>How long will we retain your personal data?</h4>
        <p>We will retain your personal data only as long as necessary for the purposes set out in this Privacy Policy, after which it will be deleted or archived except to the extent necessary to comply with our legal obligations (for example, if we are required to retain data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

        <h4>What are your rights?</h4>
        <p>We aim to be completely transparent in how we use your personal data and take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal data.</p>
        <p>You have the following data protection rights:</p>
        <ul>
          <li>The right to be informed about the collection and use of your personal data. At any time, you can file an access request with us and ask for a copy of your personal data in a commonly used electronic format.</li>
          <li>The right to request information on the purpose of data processing and information on third parties that have received your personal data.</li>
          <li>The right to ask us to rectify personal data that is incorrect or outdated.</li>
          <li>The right to restrict processing, meaning that you may request deletion of your personal data or ask us to stop processing your data at any time.</li>
          <li>The right to request the portability of your personal data.</li>
          <li>The right to raise a justified objection to processing of your personal data</li>
          <li>The right to withdraw consent for processing data at any time if you have previously provided us with consent for the processing of your personal data.</li>
        </ul>
        <p>ARI will not charge any fee for your access request but may charge an administrative fee for providing a copy of your personal information.</p>
        <p>In order to protect your personal data, we may require identification from you before releasing the requested information.</p>
        <p>We promptly consider each of your requests and provide a substantive response either by taking the requested action or by providing an explanation for why your request cannot be executed on our side.</p>
        <p>You may exercise your rights by sending a request to the contacts listed in the contact details section below.</p>
        <p>If you have any complaints about how we process your data, please start with filing a complaint at ceo@ari.company. We will try to do our best to resolve your application.</p>
        <p>In case you are not satisfied with our response, you have the right to lodge a complaint with the appropriate data protection authority at the place of your residence.</p>
        <p>Please keep in mind that if we are reaching you by email or correspondence, you may always unsubscribe from our mailing/marketing lists at any time by contacting by email indicated in this Policy or by using ‘unsubscribe’ button (link) in our email.</p>

        <h4>Where is your data processed?</h4>
        <p>Your personal data may be processed in various locations outside the European Union and the United States, each having different data protection standards. However, we will take all reasonable steps to ensure that your data is handled securely and in accordance with the highest standards provided for in the General Data Protection Regulation (GDPR, Regulation (EU) 2016/679 of the European Parliament and of the Council), this Privacy Policy, and industry standards.</p>

        <h4>Processing personal data of job applicants</h4>
        <p>As part of any recruitment process, ARI collects and processes personal data provided directly by job applicants, their referees, and recruitment companies working on our behalf. We are committed to being transparent about how the data is collected and used in compliance with our data protection obligations.</p>
        <p>Types of personal data we collect</p>
        <p>ARI collects a range of information about you that includes:</p>
        <ul>
          <li>Name, address and contact details, such as email address, telephone number, skype and links to social network profiles</li>
          <li>Details of qualifications, skills, experience and employment history</li>
          <li>Information about the current level of remuneration, including benefit entitlements</li>
          <li>Information about your entitlement to work in the EU, if required by our clients</li>
        </ul>
        <p>ARI may collect personal data in a variety of ways. For example, data may be extracted from application forms, CVs (resumes), or through interviews or other means of assessment.</p>
        <p>Personal data is stored in our servers.</p>
        <p>If you are invited to ARI for an interview, please be aware that for security reasons our premises are under video surveillance. We may collect images caught on camera.</p>
        <p>Purpose of processing</p>
        <p>Given the nature of our HR and recruiting activities, we need to process data in order to collaborate with you on possible job opportunities.</p>
        <p>ARI has a legitimate interest in processing personal data during the recruitment process and for record-keeping purposes. Processing data of job applicants allows us to manage the recruitment process, assess and confirm suitability for employment.</p>
        <p>Provided your application is unsuccessful, ARI will inform you and delete your personal data from all ARI servers.</p>
        <p>You may withdraw your consent at any time.</p>
        <p>Recipients of personal data</p>
        <p>For the purposes of recruitment, we may share your personal data, including the data stored in our servers, with affiliates, existing and prospective clients of ARI. In this case, you will be informed separately.</p>
        <p>Also, we may disclose your personal data to other third parties mentioned in this Privacy Policy.</p>
        <p>Retention period</p>
        <p>ARI keeps personal data of job applicants until the vacancy is closed (not more than two (1) years).</p>

        <h4>Cookies</h4>
        <p>As is a general practice, our website uses cookies to collect data and provide visitors with a more user-friendly browsing experience.</p>
        <p>Cookies are selected pieces of data that are stored on your computer to help a website recognize you on your next visit. Cookies automatically collect data that isn’t personally identifiable, such as preferred internet browser, operating system, domain name, website interactions, visit time, location, and most-viewed pages per visit.</p>
        <p>By accepting our Privacy Policy and remaining on the ARI website, you agree with our website’s use of cookies. You may clean or disable cookies in your internet browser settings to delete this data from your device and stop data from being collected.</p>
        <p>Typically, browsers automatically accept cookies. If you want to reject cookies, block the use of cookies, delete cookies, or be notified when a cookie is sent, please change the appropriate settings in your browser.</p>
        <p>For more detailed information on this matter, please see our <a href="/cookie-policy" target="_blank">Cookie Policy.</a></p>

        <h4>External links on the website</h4>
        <p>Our website may contain external links that take you to third-party websites. These websites operate independently of ARI and are not covered by our Privacy Policy. Data that you share on a third-party website that is linked to the ARI website is covered by that third-party website’s own privacy policy and may be collected for use by such a third party. ARI is not liable for data processed by third-party websites and cannot guarantee your safety when interacting with them.</p>

        <h4>Revisions to the Privacy Policy</h4>
        <p>ARI reserves the right to make changes to this Privacy Policy at any time. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        <p>By continuing to use our website, you accept those changes.</p>

        <h4>Contact details</h4>
        <p>If you have any questions or comments regarding this Privacy Policy, please send an email to ceo@ari.company with the subject line “Privacy Policy Request”.</p>
        <p><i>Effective: November 01, 2021</i></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPage"
}
</script>

<style lang="scss" scoped>
.privacy-page {
  flex: 1 0 auto;

  &__header {
    padding-top: 60px;
  }
  &__content {
    padding-bottom: 60px;
    word-wrap: break-word;
    h4 {
      margin: 16px 0;
    }
    p {
      font-size: 16px;
    }
    ul {
      margin-bottom: 16px;
      color: $dark-text;
    }
  }
}
</style>